import React from "react"
import styled from "styled-components"
import { Typography, Grid, Button } from "@material-ui/core"
import Appicon from "../../static/Appicon.png"

const StyledImage = styled.img`
  border-radius: 20px;
  max-width: 100%;
  height: auto;
  float: left;
`

const StyledTypograhpy = styled(Typography)`
  color: white;
`

const StyledParentGrid = styled(Grid)`
  background-color: #324e34;
  padding: 20px;
`

const StyledButton = styled(Button)`
  background-color: black;
  margin-top: 15px;
  color: white;
`

const jumbotron = () => {
  return (
    <>
      <StyledParentGrid container>
        <Grid item md={2} xs={12}>
          <StyledImage src={Appicon}></StyledImage>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <StyledTypograhpy variant="h1" gutterBottom>
            Body Hack
          </StyledTypograhpy>
          <StyledTypograhpy variant="h4" gutterBottom>
            Workout With A Purpose
          </StyledTypograhpy>
          <StyledButton variant="contained">
            Download on App Store
            </StyledButton>
          <StyledButton variant="contained">
            Download on Google Play Store
          </StyledButton>
          <StyledButton variant="contained">
            Personal Trainers Get Started Here
          </StyledButton>
        </Grid>
      </StyledParentGrid>
    </>
  )
}

export default jumbotron
