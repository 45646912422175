import React from "react"
import Header from "../components/header"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import Jumbotron from "../components/jumbotron.js"
import MobileApp from "../components/mobile-app"
import WebApp from "../components/web-app"

const StyledRootGrid = styled(Grid)`
  padding: 15px;
`

export default function Home() {
  return (
    <>
      <Header />
      <StyledRootGrid spacing={5} container>
        <Jumbotron />
        <MobileApp />
        <WebApp />
      </StyledRootGrid>
    </>
  )
}
