import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import webDashboard from "../../static/webDashboard.png"
import userDashboard from "../../static/userDashboard.png"
import routineDashboard from "../../static/routineCreation.png" 

const StyledHeading = styled(Typography)`
  text-align: center;
`

const StyledImage = styled.img`
  border-radius: 20px;
  max-width: 100%;
  height: auto;
  float: right;
`
const StyledGrid = styled(Grid)`
  padding: 15px;
`
const webApp = () => {
  return (
    <>
      <Grid item xs={12}>
        <StyledHeading variant="h4" gutterBottom>
          Personal Trainer View - Website
        </StyledHeading>
      </Grid>
      <StyledGrid container spacing={2} direction="row">
        <Grid item md={6} xs={12}>
          <StyledImage src={webDashboard}></StyledImage>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography >
            The Personal trainer dashboard shows you all your indivdual clients and their
           workout routine.
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>
          The ability to build a routine from scratch by adding exercises, adjusting the reps, sets, weight all simultaneously. 
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <StyledImage src={routineDashboard}></StyledImage>
        </Grid>
        <Grid item md={6} xs={12}>
          <StyledImage src={userDashboard}></StyledImage>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>
          The client dashboard page shows workouts completed, interactive graphs, and their full routine. 
          </Typography>
        </Grid>
      </StyledGrid>
    </>
  )
}

export default webApp
