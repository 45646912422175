import React from "react"
import styled from "styled-components"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import { Link } from "gatsby"

const StyledAppBar = styled(AppBar)`
  background-color: #1b1d2a;
`

const StyledButton = styled(Button)`
  color: white;
`

export default function header() {
  return (
    <StyledAppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <StyledButton variant="h6">Dashboard</StyledButton>
        <StyledButton variant="h6">Pricing</StyledButton>
        <StyledButton variant="h6">About Us</StyledButton>
        <StyledButton variant="h6">Gainz Web</StyledButton>

        <StyledButton variant="h6">Login</StyledButton>
        <StyledButton variant="h6">Sign Up</StyledButton>
      </Toolbar>
    </StyledAppBar>
  )
}
