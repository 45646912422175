import React from "react"
import styled from "styled-components"
import {
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@material-ui/core"


const StyledCard = styled(Card)`

`;

const StyledCardMedia = styled(CardMedia)`
  height: 50%;
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  padding-top: 15px;
`;


const MediaCard = (props) => {
    return (
      <StyledCard>
      <CardActionArea>
        <StyledCardMedia
          component="img"
          image={props.image}
        />
        <CardContent>
        <StyledTypography gutterBottom variant="h5" component="h2">
              {props.title}
            </StyledTypography>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.description}
            </Typography>
        </CardContent>
      </CardActionArea>
    </StyledCard>
    )
}

export default MediaCard;