import React from "react"
import styled from "styled-components"
import {
  Typography,
  Grid,
} from "@material-ui/core"
import dayview from "../../static/dayview.png"
import weekview from "../../static/weekview.png"
import editmode from "../../static/editmode.png"
import completedview from "../../static/completedview.png"
import MediaCard from "./MediaCard"

const StyledHeading = styled(Typography)`
  text-align: center;
`

const mobileApp = () => {
  return (
    <>
      <Grid item xs={12}>
        <StyledHeading variant="h4" gutterBottom>
          Client View - Mobile App
        </StyledHeading>
      </Grid>


    <Grid 
      container
      justify="center"
      alignItems="center"
      spacing={2}>
      
      <Grid
        item
        xs={12}
        md={2}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <MediaCard
          image={weekview}
          title="Week View"
          description="lets users see upcoming workouts. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit."
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <MediaCard
          image={dayview}
          title="Day View"
          description="lets users see upcoming workouts. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit."
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <MediaCard
          image={editmode}
          title="Edit View"
          description="lets users see edit workouts. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. consectetur adipiscing elit"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <MediaCard
          image={completedview}
          title="Completed"
          description="lets users see edit workouts. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. consectetur adipiscing elit"
        />
      </Grid>
    </Grid>
    </>
  )
}

export default mobileApp
